<template>
  <!-- eslint-disable max-len-->
  <div class="modalbox modalStep">
    <div class="is-flex bigmodal">
      <router-link v-if="route_from == 'WaypointEdit'" class="mr-auto" :to="'/point-etape-edition/' +
        $route.params.idStudentTraining +
        '/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idWaypoint
        "><img class="btn-previous" :src="require('@/assets/img/arrows/previous.svg')" /></router-link>
      <router-link v-else class="mr-auto" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining
        "><img class="btn-previous" :src="require('@/assets/img/arrows/previous.svg')" /></router-link>
    </div>
    <section class="w-100" id="freeCarousel">
      <b-carousel v-model="carousel" :animated="animated" :has-drag="drag" :autoplay="autoPlay"
        :pause-hover="pauseHover" :pause-info="pauseInfo" :pause-info-type="pauseType" :interval="interval"
        :repeat="repeat" :arrow-hover="false" :indicator="false" :key="componentKey">
        <b-carousel-item>
          <section class="hero is-medium is-bold">
            <div class="">
              <div class="w-100 bloc-title">
                <div class="title-paragraph">
                  <div class="stepsCount">1/2</div>
                  Enregistrez une nouvelle expérience
                </div>

                <div class="subtitle-paragraph mb-4">
                  Vous pouvez ici prendre des notes rapides ou rendre compte
                  d'une activité qui n'était pas prévue dans votre parcours ou
                  votre formation.
                </div>
              </div>
              <div class="w-100 container-editor">
                <ModalImage v-if="isCardModalActive" :fileImage.sync="imageUploaded" :imageResized.sync="imageResized"
                  @close="closeModal" @loaded="addImageinSteps(imageResized)" />
                <b-field label="Nom">
                  <b-input v-model="formRevision.title" placeholder="précisez un titre"></b-input>
                </b-field>
                <div class="columns is-vcentered mb-5 pb-1">
                  <b-field class="column is-narrow mr-3 mb-0">
                    <b-select placeholder="Type de situation" icon="earth" v-model="formRevision.type">
                      <option value="1">Situation vécue en entreprise</option>
                      <option value="0">
                        Situation vécue en centre de formation
                      </option>
                    </b-select>
                  </b-field>
                  <div class="has-text-primary columns is-vcentered is-multiline mx-4 mt-3 mb-3">
                    <div class="is-flex align-items-center mb-2">
                      <b class="mr-3">L'activité a eu lieu aujourd'hui ?</b>

                      <b-switch v-if="newItem" v-model="activityToday" true-value="oui" false-value="non"
                        native-value="oui" @input="changeDate">{{ activityToday }}
                      </b-switch>
                    </div>
                    <div class="is-half-desktop is-flex align-items-center mb-2" v-if="activityToday !== 'oui'">
                      du {{ formRevision.start_date | day }} au
                      {{ formRevision.end_date | day }}
                      <b-button v-if="!editDates" type="is-rounded mr-2 ml-2" size="is-small"
                        @click="editDates = !editDates"><span>modifier</span></b-button>
                    </div>
                  </div>
                </div>
                <div v-show="editDates">
                  <b-button type="is-rounded mr-2" @click="editDates = !editDates">refermer</b-button>
                  <div class="columns is-tablet is-vcentered mt-2">
                    <div class="column is-narrow">
                      <b-field label="Date de début">
                        <b-datetimepicker v-model="formRevision.start_date" :locale="locale" :datepicker="{
                          firstDayOfWeek: 1,
                          showWeekNumber: true,
                        }" icon="calendar-today" trap-focus inline @input="verifToday">
                        </b-datetimepicker>
                      </b-field>
                    </div>
                    <div class="column is-narrow">
                      <b-field label="Date de fin">
                        <b-datetimepicker v-model="formRevision.end_date" :show-week-number="showWeekNumber"
                          :locale="locale" icon="calendar-today" trap-focus inline :datepicker="{
                            firstDayOfWeek: 1,
                            showWeekNumber: true,
                          }" @input="verifToday">
                        </b-datetimepicker>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="imgBottom fade">
                  <b-upload v-model="file" class="file-label" @input="uploadImageModal(i)">
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="photo"></b-icon>
                    </span>
                  </b-upload>
                </div>
                <div class="is-flex">
                  <b-field class="file is-primary mb-1 addImage" :class="{ 'has-name': !!file }">
                    <b-upload v-model="file" class="file-label" @input="uploadImageModal">
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="photo"></b-icon>
                        <span class="file-label">Ajouter une image</span>
                      </span>
                    </b-upload>
                  </b-field>
                </div>
                <div class="ck-editor-block">
                  <ckeditor v-model="formRevision.entry" :editor="editor" :config="editorConfig" class="mb-5"
                    :ref="'revisionContent'"></ckeditor>
                </div>
              </div>
            </div>
          </section>
        </b-carousel-item>
        <b-carousel-item v-if="this.$route.params.idFree !== 'new'">
          <section class="hero is-medium is-bold">
            <div class="">
              <div class="bloc-title">
                <div class="title-paragraph">
                  <div class="stepsCount">2/2</div>
                  Avez-vous acquis de nouvelles compétences ?
                </div>
              </div>
              <div class="container-editor">
                <div class="subtitle-paragraph mb-4">
                  Cette évaluation est facultative mais vous pouvez indiquer, si
                  vous arrivez à les identifier, les compétences que vous avez
                  travaillées. Ici, vous retrouvez la liste complète des
                  compétences que vous devrez acquérir lors de votre formation.
                </div>

                <SkillEvaluationSelect type="free" />
              </div>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
    </section>
    <div>
      <div class="is-flex is-flex-wrap-wrap activityBtns align-items-center" style="z-index: 2000">
        <div class="w-100 is-flex">
          <b-button v-if="$route.params.idFree === 'new'" @click="postRevision" class="mx-auto btn-action"
            type="is-primary is-rounde" rounded :disabled="conditions === false || isSubmitted">créer</b-button>
          <b-button v-else class="mx-auto btn-action" type="is-primary is-rounded" rounded
            @click="patchRevision">enregistrer</b-button>
        </div>
        <div class="help-btn">
          <AppHelpMessage title="Enregistrez une expérience libre" message="<b>Vous pouvez ici rendre compte d'une activité
               qui n'était pas prévue dans votre parcours ou que vous avez du mal à
               associer à une situation de travail proposée.</b> <br><br>N'hésitez pas dans
               l'étape 2 à désigner quelles compétences vous avez travaillé dans cette activité. "
            textBtn="Besoin d'aide ?" />
        </div>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import _ from 'lodash';
import ModalImage from '@/components/Resources/ModalImage.vue';
import SkillEvaluationSelect from '@/components/Skills/SkillEvaluationSelect.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    studentactivity: [Object, Array],
    slide: Number,
  },
  components: {
    ckeditor: CKEditor.component,
    ModalImage,
    SkillEvaluationSelect,
    AppHelpMessage,
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      isSubmitted: false,
      sequencetype: '5',
      isLoading: true,
      isFullPage: false,
      carousel: 0,
      animated: 'slide',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 3000,
      editDates: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      imageUploaded: {},
      modalKey: 0,
      imageResized: null,
      file: null,
      started: true,
      formRevision: {
        id: '',
        title: '',
        entry: '',
        start_date: new Date(),
        end_date: new Date(),
      },
      studentrevision: '',
      isCardModalActive: false,
      showWeekNumber: true,
      locale: 'UTC',
      today: new Date(),
      activityToday: 'oui',
      newItem: true,
      componentKey: 0,
      route_from: '',
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
    conditions() {
      return this.formRevision.title.length > 0 && this.formRevision.entry.length > 0;
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initRevision() {
      this.$http
        .get(`/agenda/free_situation/${this.$route.params.idFree}/?format=json`)
        .then((response) => {
          this.studentrevision = response.data;
          this.setdata();
          this.forceRerender();
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    setdata() {
      this.formRevision.title = this.studentrevision.title;
      this.formRevision.entry = this.studentrevision.entry;
      this.formRevision.start_date = new Date(this.studentrevision.start_date);
      this.formRevision.end_date = new Date(this.studentrevision.end_date);
      this.formRevision.type = this.studentrevision.type;
      this.verifToday();
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    uploadImageModal() {
      this.checkFile();
      if (this.fileValidity === true) {
        this.imageUploaded = this.file;
        this.modalKey += 1;
        this.isCardModalActive = true;
      }
    },
    closeModal() {
      this.isCardModalActive = false;
      this.file = null;
    },
    addImageinSteps(url) {
      this.formRevision.entry = `${this.formRevision.entry} <div class="imageUploaded"><img src="${url}"/></div>`;
    },
    checkFile() {
      const extension = this.file.name.split('.').pop();
      if (!/(jpeg|png|jpg)$/ig.test(extension)) {
        this.$buefy.dialog.alert({
          title: 'Ce type de fichier n\'est pas accepté',
          message: 'Vous pouvez utiliser les formats suivants :  jpeg, jpg, png.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.size > 10000000) {
        this.$buefy.dialog.alert({
          title: 'Cette image est trop volumineuse',
          message: 'Vous pouvez télécharger une image dont la taille ne dépasse pas 10 Mo. Vous pouvez utiliser un service externe comme squoosh.app ou utiliser les options de votre périphérique (téléphone ou tablette) pour réduire une image.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.name.length > 116) {
        this.$buefy.dialog.alert({
          title: 'Le nom de votre fichier est trop long',
          message: 'le nom ne doit pas dépasser 117 cractères',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else { this.nameFile = this.file.name; this.fileValidity = true; }
    },
    patchRevision() {
      this.$http
        .patch(`/agenda/free_situation/${this.$route.params.idFree}/`, {
          profile: this.currentUser.id,
          role: this.currentUser.role,
          title: this.formRevision.title,
          entry: this.formRevision.entry,
          start_date: this.formRevision.start_date,
          end_date: this.formRevision.end_date,
          type: this.formRevision.type,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    postRevision() {
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        this.$http
          .post('agenda/free_situation/', {
            profile: this.currentUser.id,
            role: this.currentUser.role,
            title: this.formRevision.title,
            entry: this.formRevision.entry,
            start_date: this.formRevision.start_date,
            end_date: this.formRevision.end_date,
            type: this.formRevision.type,
            student_training: this.$route.params.idStudentTraining,
          }).then((response) => {
            this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
            this.$router.push({
              name: 'StudentFreeSituationEdit',
              params: {
                idTraining: this.$route.params.idTraining,
                idStudent: this.$route.params.idStudent,
                idSequence: this.$route.params.idSequence,
                idActivity: this.$route.params.idActivity,
                idWaypoint: this.$route.params.idWaypoint,
                idFree: response.data.id,
                slide: 2,
              },
            });
            this.forceRerender();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'L\'activité n\'a pas pu être enregistrée.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    verifToday() {
      if (this.formRevision.start_date !== this.today) {
        this.activityToday = 'non';
      } else if (this.formRevision.start_date === this.today) {
        this.activityToday = 'oui';
      }
    },
    changeDate() {
      if (this.activityToday === 'oui') {
        this.formRevision.start_date = this.today;
        this.formRevision.end_date = this.today;
        this.editDates = false;
      } else {
        this.editDates = true;
      }
    },
    logActivity() {
      const typeAction = 'écriture free_situation';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    forceRerender() {
      this.componentKey += 1;
      if (this.$route.params.slide) {
        this.carousel = this.$route.params.slide;
      }
    },
    changeSlide() {
      this.$router.push({
        name: 'StudentFreeSituationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idSequence: this.$route.params.idSequence,
          idActivity: this.$route.params.idActivity,
          idWaypoint: this.$route.params.idWaypoint,
          idFree: 118,
          slide: 2,
          from: this.$route.params.from,
        },
      });
    },
  },
  created() {
    this.route_from = this.$route.params.from;

    this.endLoading();
    if (this.$route.params.idFree !== 'new') {
      this.initRevision();
      this.newItem = false;
    }
    if (this.$route.params.slide) {
      this.carousel = this.$route.params.slide;
    }
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .modalStep .bigmodal {
    margin-bottom: 0.1rem;
  }
}
</style>
